import { AnalyticsBrowser } from '@segment/analytics-next';
import Brands from 'src/constants/Brands';
import { env } from 'src/env.mjs';
import { useUser } from 'src/hooks/User/useUser';
import { useEffect } from 'react';

import { EventProps } from './EventProps';

const analytics = AnalyticsBrowser.load(
    {
        writeKey: env.NEXT_PUBLIC_SEGMENT_WRITE_KEY,
        cdnURL: env.NEXT_PUBLIC_SEGMENT_CDN_URL,
    },
    {
        integrations: {
            'Segment.io': {
                apiHost: env.NEXT_PUBLIC_SEGMENT_API_HOST,
            },
        },
    },
);

export const identify = (
    userId: string,
    traits?: { [key: string]: string },
) => {
    analytics.identify(userId, {
        brand: Brands.MOSHY,
        ...traits,
    });
};

export const page = (
    name: string,
    category?: string,
    properties?: {
        [key: string]: string | Array<string> | null | undefined | boolean;
    },
) => {
    analytics.page(category, name, {
        url: window.location.href,
        path: window.location.pathname,
        ...properties,
    });
};

export const track = (
    eventName: string,
    properties?: EventProps,
    callback?: () => void,
) => {
    analytics.track(
        eventName,
        {
            brand: Brands.MOSHY,
            url: window.location.href,
            path: window.location.pathname,
            ...properties,
        },
        callback,
    );
};

export const setAnonymousId = (id: string) => {
    analytics.setAnonymousId(id);
};

type AnalyticsProviderProps = {
    children: React.ReactNode;
};

function AnalyticsProvider({ children }: AnalyticsProviderProps) {
    const { data } = useUser();

    const identify = (userId: string, traits: Record<string, string>) => {
        analytics.identify(userId, traits);
    };

    useEffect(() => {
        if (!data || !data.id || !data.email) return;

        identify(data.id, {
            email: data.email,
            phone: data.phoneNumber,
        });
    }, [data]);

    return <>{children}</>;
}

export default AnalyticsProvider;
